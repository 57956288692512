.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInLeft {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%,0, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft {
    0% {
        -webkit-transform:translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
} 



.fadeInLeft1 {
    -webkit-animation-name: fadeInLeft1;
    animation-name: fadeInLeft1;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInLeft1 {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInLeft1 {
    0% {
        -webkit-transform:translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}



.presentation-fadeIn0{
    -webkit-animation-name: presentationFadeIn;
    animation-name: presentationFadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 1s;
}

@-webkit-keyframes presentationFadeIn {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes presentationFadeIn {
    0% {
        opacity: 0;
     }
     100% {
         opacity: 1;
     }
}

.presentation-fadeIn1{
    -webkit-animation-name: presentationFadeIn;
    animation-name: presentationFadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 1.5s;
}

@-webkit-keyframes presentationFadeIn {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes presentationFadeIn {
    0% {
        opacity: 0;
     }
     100% {
         opacity: 1;
     }
}

.presentation-fadeIn2{
    -webkit-animation-name: presentationFadeIn;
    animation-name: presentationFadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 2s;
}

@-webkit-keyframes presentationFadeIn {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes presentationFadeIn {
    0% {
        opacity: 0;
     }
     100% {
         opacity: 1;
     }
}


.presentation-fadeIn3{
    -webkit-animation-name: presentationFadeIn;
    animation-name: presentationFadeIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay: 2.5s;
}

@-webkit-keyframes presentationFadeIn {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes presentationFadeIn {
    0% {
        opacity: 0;
     }
     100% {
         opacity: 1;
     }
}



